@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}
.light-mode {
  background-color: $lightBackground2;
}
html {
  background-color: $buttonColor;
}